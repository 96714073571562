<template>
    <div
        class="add-card"
        :class="{ 'add-card-edit': isEdit }"
        @click="handleOpenDialog"
    >
        <i class="iconfont icon-daibangongzuotai-xinjiandaiban"></i>
    </div>
</template>
<script>
export default {
    name: 'AddCard',
    props: {
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleOpenDialog () {
            if (!this.isEdit) {
                return
            }
            this.$emit('handle-dialog', true, { gridBox: this.$attrs })
        }
    }
}
</script>
<style lang="scss" scoped>
.add-card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #288bff;
    background-color: rgba(105, 146, 188, 0.6);
    border: rgba(251, 253, 255);
    border-radius: 0.2rem;
    opacity: 0;
    transition: opacity 0.3s;

    i {
        font-size: 0.3rem;
    }

    &-edit {
        cursor: pointer;

        &:hover {
            opacity: 1;
        }
    }
}
</style>
